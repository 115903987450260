import React, { Component } from 'react';
import { string, func, bool, object, shape } from 'prop-types';
import { Form as FinalForm, Field  } from 'react-final-form';
import classNames from 'classnames';

import { intlShape, injectIntl, FormattedMessage } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { maxLength, required, composeValidators, numberAtLeast } from '../../../util/validators';
import { propTypes } from '../../../util/types';
import { Form, FieldTextInput, Button } from '../../../components';
import css from './PaymentGoogleForm.module.css';
import { invalid } from "moment";
import { withRouter } from 'react-router-dom';
import routeConfiguration from '../../../routing/routeConfiguration';
import { pathByRouteName } from '../../../util/routes';

const onSubmit = (values, form, callback) => {
  console.log({ values, form, callback })
}
class PaymentGoogleForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleGoogleSubmit = this.handleGoogleSubmit.bind(this);
    this.paymentForm = this.paymentForm.bind(this);
  }

// ないとエラーでる：Uncaught Error: No onSubmit function specified
  handleSubmit = values => {
    const { onSubmit } = this.props;
    const params = {
      formValues: values,
    };
    console.log("submitテスト")
    onSubmit(params);
  };

  handleGoogleSubmit = () => {
    this.setState(prevState => ({ ...prevState, submitted: true }))
  }
    
  paymentForm(formRenderProps) {
    const {
        className,
        disabled,
        ready,
        pristine,
        updateInProgress,
        title,
        values,
        customer,
        customerMail,
        customerPhoneNumber,
        listingId,
        price,
        form,
        intl,
        valid,
        history,
        name
      } = formRenderProps;

      const classes = classNames(css.root, className);
    const updated = true; 
      const invalid = true;

      const submitReady = (updated && pristine) || ready;

      const submitInProgress = updateInProgress;
  
      const nameLabel = intl.formatMessage(
        {
          id: 'PaymentGoogleForm.nameLabel',
        }
      );
      const namePlaceholder = intl.formatMessage(
        {
          id: 'PaymentGoogleForm.namePlaceholder',
        }
      );
      const mailLabel = intl.formatMessage(
        {
          id: 'PaymentGoogleForm.mailLabel',
        }
      );
      const mailPlaceholder = intl.formatMessage(
          {
            id: 'PaymentGoogleForm.mailPlaceholder',
          }
      );
      const telLabel = intl.formatMessage(
        {
          id: 'PaymentGoogleForm.telLabel',
        }
      );
      const telDiscription = intl.formatMessage(
        {
          id: 'PaymentGoogleForm.telDiscription',
        }
      );
      const telPlaceholder = intl.formatMessage(
          {
            id: 'PaymentGoogleForm.telPlaceholder',
          }
      );
      const telErrorMessage =
        intl.formatMessage({
          id: 'PaymentGoogleForm.telErrorMessage',
        });
      const telRequired = required(
        intl.formatMessage({
          id: 'PaymentGoogleForm.telPlaceholder',
        })
      );
      const idLabel = intl.formatMessage(
        {
          id: 'PaymentGoogleForm.idLabel',
        }
      );
      const idPlaceholder = intl.formatMessage(
          {
            id: 'PaymentGoogleForm.idPlaceholder',
          }
      );
      const titleLabel = intl.formatMessage(
        {
          id: 'PaymentGoogleForm.titleLabel',
        }
      );
      const titlePlaceholder = intl.formatMessage(
          {
            id: 'PaymentGoogleForm.titlePlaceholder',
          }
      );
      const priceLabel = intl.formatMessage(
        {
          id: 'PaymentGoogleForm.priceLabel',
        }
      );
      const pricePlaceholder = intl.formatMessage(
          {
            id: 'PaymentGoogleForm.pricePlaceholder',
          }
      );

      const postalCodeLabel = intl.formatMessage(
        {
          id: 'PaymentGoogleForm.postalCodeLabel',
        }
      );
      const postalCodePlaceholder = intl.formatMessage(
          {
            id: 'PaymentGoogleForm.postalCodePlaceholder',
          }
      );
      const postalCodeRequired = required(
        intl.formatMessage({
          id: 'PaymentGoogleForm.postalCodePlaceholder',
        })
      );
      const stateLabel = intl.formatMessage(
        {
          id: 'PaymentGoogleForm.stateLabel',
        }
      );
      const statePlaceholder = intl.formatMessage(
          {
            id: 'PaymentGoogleForm.statePlaceholder',
          }
      );
      const stateRequired = required(
        intl.formatMessage({
          id: 'PaymentGoogleForm.statePlaceholder',
        })
      );
      const cityLabel = intl.formatMessage(
        {
          id: 'PaymentGoogleForm.cityLabel',
        }
      );
      const cityPlaceholder = intl.formatMessage(
          {
            id: 'PaymentGoogleForm.cityPlaceholder',
          }
      );
     const cityRequired = required(
        intl.formatMessage({
          id: 'PaymentGoogleForm.cityPlaceholder',
        })
      );
      const addressLine1Label = intl.formatMessage(
        {
          id: 'PaymentGoogleForm.addressLine1Label',
        }
      );
      const addressLine1Placeholder = intl.formatMessage(
          {
            id: 'PaymentGoogleForm.addressLine1Placeholder',
          }
      );
    const addressLine1Required = required(
        intl.formatMessage({
          id: 'PaymentGoogleForm.addressLine1Placeholder',
        })
      );
      const addressLine2Label = intl.formatMessage(
        {
          id: 'PaymentGoogleForm.addressLine2Label',
        }
      );
      const addressLine2Placeholder = intl.formatMessage(
          {
            id: 'PaymentGoogleForm.addressLine2Placeholder',
          }
      );
        
    const isValid = (valid,values)=>{
        if (valid) { return valid }
        const isExceptionValid =
          [ values.postalCode,
            values.addressLine1,
            values.city,
            values.state].every((value) => value)
        return isExceptionValid
      }

    const submitDisabled = !isValid(valid, values) || disabled;
    const path = pathByRouteName('BankTransferDetailPage', routeConfiguration());
    const isProduction = process.env.REACT_APP_ENV === 'production'
    const formAction = isProduction ? "https://docs.google.com/forms/u/0/d/e/1FAIpQLSfQaAgsb_lPAv7pN-sOlCQy0IHPeqZyiVUFDfxOf0Oy21mOCg/formResponse"
        : "https://docs.google.com/forms/u/0/d/e/1FAIpQLSedwajwPlko2sXItfr9R3ui6xZRyDOf6w-dEd1jVOvLLnYhvw/formResponse";
            
    const devMaster = {
      listId: "entry.246314639",
      title: "entry.995337290",
      price: "entry.1203700199",
      name: "entry.614352089",
      mail: "entry.742326909",
      tel: "entry.118432561",
      postalCode: "entry.780845053",
      state: "entry.1155215566",
      city: "entry.607102507",
      addressLine1: "entry.321244024",
      addressLine2: "entry.668224425"
    }
    const prodMaster = {
      listId: "entry.1635961791",
      title: "entry.1701386136",
      price: "entry.1267791295",
      name: "entry.343409858",
      mail: "entry.921740260",
      tel: "entry.2048395630",
      postalCode: "entry.1338089041",
      state: "entry.1243623567",
      city: "entry.1837463700",
      addressLine1: "entry.1101625832",
      addressLine2: "entry.773916084"
    }
    const master = isProduction ? prodMaster : devMaster

    return (
        <form
          className={classes}
          action={formAction}
          method="POST"
          target="hidden_iframe"
          onSubmit={this.handleGoogleSubmit}
        >
          <iframe
            src=""
            name="hidden_iframe"
            id="hidden_iframe"
            hidden
            onLoad={() => {
              if (this.state.submitted) {
                history.push(path)
              }
            }}
          ></iframe>
           <div className={css.googleFormField}>
          <label>
            {idLabel}
            <input
            className={css.field}
            type="text"
            name={master.listId}
            id="listId"
            placeholder={idPlaceholder}
            defaultValue={listingId}
            readOnly
          >
          </input>
          </label>
          </div>
          <div className={css.googleFormField}>
          <label>
            {titleLabel}
            <input
              className={css.field}
              type="text"
              name={master.title}
              id="title"
              placeholder={titlePlaceholder}
              defaultValue={title}
              readOnly
            >
            </input>
          </label>
          </div>
           <div className={css.googleFormField}>
          <label>
            {priceLabel}
            <input
              className={css.field}
              type="text"
              name={master.price}
              id="price"
              placeholder={pricePlaceholder}
              defaultValue={price}
              readOnly
            >
            </input>
          </label>
          </div>
          <h3 className={css.heading}>購入者情報</h3>
          <div>
          <label>
            {nameLabel}
            <input
              className={css.field}
              type="text"
              name={master.name}
              id="name"
              placeholder={namePlaceholder}
              defaultValue={customer}
              readOnly
            >
            </input>
          </label>
          </div>
          <div className={css.googleFormField}>
          <label>
            {mailLabel}
            <input
              className={css.field}
              type="email"
              name={master.mail}
              id="mail"
              placeholder={mailPlaceholder}
              defaultValue={customerMail}
              readOnly
            >
            </input>
          </label>
          </div>
           <div className={css.googleFormField}>
            <label>{telLabel}
            <p className={css.discription}>{telDiscription}</p>
            <input
              className={css.field}
              type="number"
              name={master.tel}
              id="tel"
              placeholder={telPlaceholder}
              defaultValue={customerPhoneNumber}
              required
            >
            </input>
          </label>
          </div>
          <h3 className={css.heading}>配送先住所</h3>
           <div>
          <FieldTextInput
            className={css.field}
            type="text"
            name={master.postalCode}
            id="postalCode"
            label={postalCodeLabel}
            placeholder={postalCodePlaceholder}
            validate={postalCodeRequired}
            values="true"
            />
          </div>
           <div className={css.googleFormField}>
          <FieldTextInput
            className={css.field}
            type="text"
            name={master.state}
            id="state"
            label={stateLabel}
            placeholder={statePlaceholder}
            validate={stateRequired}
            values="true"
            />
          </div>
           <div className={css.googleFormField}>
          <FieldTextInput
            className={css.field}
            type="text"
            name={master.city}
            id="city"
            label={cityLabel}
            placeholder={cityPlaceholder}
            validate={cityRequired}
            values="true"
            />
          </div>
           <div className={css.googleFormField}>
          <FieldTextInput
            className={css.field}
            type="text"
            name={master.addressLine1}
            id="addressLine1"
            label={addressLine1Label}
            placeholder={addressLine1Placeholder}
            validate={addressLine1Required}
            values="true"
            />
          </div>
           <div className={css.googleFormField}>
          <FieldTextInput
            className={css.field}
            type="text"
            name={master.addressLine2}
            id="addressLine2"
            label={addressLine2Label}
            placeholder={addressLine2Placeholder}
            values="true"
          />
          </div>
          
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
            onSubmit={onSubmit}
          >
            購入する
          </Button>
        </form>
      );
  }
  render() {
    const { onSubmit, ...rest } = this.props;
    return <FinalForm onSubmit={this.handleSubmit} {...rest} render={this.paymentForm} />;
  }
}

PaymentGoogleForm.defaultProps = {
  className: null,
};

PaymentGoogleForm.propTypes = {
  className: string,
  // inProgress: bool,
  intl: intlShape.isRequired,

  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: object,
};

export default injectIntl(withRouter(PaymentGoogleForm));